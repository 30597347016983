import React from 'react'
import styled from 'styled-components'

import Markdown from '../../utils/markdown'

export default function ContactPerson({ contactPerson }) {
    return (
        <ContactPersonContainer className="contact-person">
            <figure>
                <img src={contactPerson.data.Attachments[0].url} alt="" />
            </figure>
            <div>
                <h2>{contactPerson.data.Headline}</h2>
                <Markdown content={contactPerson.data.Content} />
                <Markdown content={contactPerson.data.HTML} />
            </div>
        </ContactPersonContainer>
    )
}

const ContactPersonContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(25, 1fr);

    figure {
        margin: 0;
        grid-column: 3 / -2;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 4 / span 9;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 6 / span 9;
        }
    }

    div {
        grid-column: 3 / -2;
        font-family: 'SawtonBauhaus';
        margin-top: 3rem;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 14 / span 10;
            margin-top: 0;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 16 / span 9;
        }

        h2 {
            padding-top: 0;
        }

        a {
            &:not([href*='tel']) {
                text-decoration: underline;
            }
        }
    }
`
