import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import { isType } from '../utils/typecheck'
import Layout from '../components/layout'
import ContactPerson from '../components/sections/contactPerson'
import NextArticles from '../components/sections/nextArticles'
import Markdown from '../utils/markdown'
import TwoImages from '../components/sections/twoImages'
import { WebVideo } from '../components/webVideo'
// import SEO from '../components/seo'

export const query = graphql`
    query GetProjects($Path: String!) {
        allAirtable(
            filter: { table: { eq: "Projekte" }, data: { Slug: { eq: $Path } } }
            sort: { order: ASC, fields: rowIndex }
        ) {
            nodes {
                data {
                    Type
                    Content
                    Attachments {
                        src
                        srcSet
                        sizes
                    }
                    Vimeo_ID
                    YouTube_ID
                    Ansprechpartner {
                        data {
                            Headline
                            Content
                            HTML
                            Attachments {
                                src
                                srcSet
                                sizes
                            }
                        }
                    }
                    Magazin_Slugs
                }
            }
        }
    }
`

export default ({ data }) => {
    const items = data.allAirtable.nodes
    const content = items.map((item, index) => {
        let formattedContent = (
            <Markdown content={item.data.Content} p={false} />
        )

        switch (true) {
            case isType(item.data.Type, 'H1'):
                return <h1 key={index}>{formattedContent}</h1>

            case isType(item.data.Type, 'H2'):
                return <h2 key={index}>{formattedContent}</h2>

            case isType(item.data.Type, 'Teasertext'):
                return (
                    <p key={index} className="teaser">
                        {formattedContent}
                    </p>
                )

            case isType(item.data.Type, 'Tags'):
                return (
                    <div key={index} className="tags">
                        {formattedContent}
                    </div>
                )

            case isType(item.data.Type, 'Titelbild') &&
                !isType(item.data.Type, 'Video'):
                return (
                    <figure key={index} className="cover-picture">
                        <img
                            alt=""
                            srcSet={item.data.Attachments[0].srcSet}
                            sizes={item.data.Attachments[0].sizes}
                        />
                        <figcaption>{formattedContent}</figcaption>
                    </figure>
                )

            case isType(item.data.Type, 'Titelbild') &&
                isType(item.data.Type, 'Video'):
                return (
                    <div key={index} className="cover-video">
                        <WebVideo
                            type={item.data.Vimeo_ID ? 'vimeo' : 'youtube'}
                            videoId={item.data.Vimeo_ID ?? item.data.YouTube_ID}
                            figcaption={formattedContent}
                            thumbnail={item.data.Attachments?.[0]?.src}
                        />
                    </div>
                )

            case !isType(item.data.Type, 'Titelbild') &&
                isType(item.data.Type, 'Video'):
                return (
                    <div key={index} className="single-image">
                        <WebVideo
                            type={item.data.Vimeo_ID ? 'vimeo' : 'youtube'}
                            videoId={item.data.Vimeo_ID ?? item.data.YouTube_ID}
                            figcaption={formattedContent}
                            thumbnail={item.data.Attachments?.[0]?.src}
                        />
                    </div>
                )

            case isType(item.data.Type, 'Projektdaten'):
                return (
                    <React.Fragment key={index}>
                        <div className="project-data">{formattedContent}</div>
                        <figure className="project-logo">
                            {!!item.data.Attachments && (
                                <img
                                    alt=""
                                    srcSet={item.data.Attachments[0].srcSet}
                                    sizes={item.data.Attachments[0].sizes}
                                />
                            )}
                        </figure>
                    </React.Fragment>
                )

            case isType(item.data.Type, 'Fließtext'):
                return <Markdown key={index} content={item.data.Content} />

            case isType(item.data.Type, 'Zitat'):
                return (
                    <figure className="quote" key={index}>
                        <blockquote>{formattedContent}</blockquote>
                        {isType(items[index + 1].data.Type, 'BU') && (
                            <figcaption>
                                <cite>
                                    <Markdown
                                        content={items[index + 1].data.Content}
                                    />
                                </cite>
                            </figcaption>
                        )}
                    </figure>
                )

            case isType(item.data.Type, 'H4'):
                return (
                    <aside key={index} className="infobox">
                        {formattedContent}
                    </aside>
                )

            case isType(item.data.Type, 'Bild'):
                return (
                    <figure key={index} className="single-image">
                        {!!item.data.Attachments && (
                            <img
                                alt=""
                                srcSet={item.data.Attachments[0].srcSet}
                                sizes={item.data.Attachments[0].sizes}
                            />
                        )}
                        <figcaption>{formattedContent}</figcaption>
                    </figure>
                )

            case isType(item.data.Type, 'Bilderstrecke-breit-1'):
            case isType(item.data.Type, 'Bilderstrecke-breit-2'):
            case isType(item.data.Type, 'Bilderstrecke-schmal-1'):
            case isType(item.data.Type, 'Bilderstrecke-schmal-2'):
                return (
                    <TwoImages
                        key={index}
                        type={item.data.Type}
                        images={item.data.Attachments}
                        figcaptions={item.data.Content}
                    />
                )

            case isType(item.data.Type, 'Ansprechpartner'):
                return (
                    <ContactPerson
                        key={index}
                        contactPerson={item.data.Ansprechpartner[0]}
                    />
                )

            case isType(item.data.Type, 'Magazin'):
                return (
                    <NextArticles
                        key={index}
                        heading={item.data.Content}
                        articlesCount={item.data.Magazin_Slugs.length}
                        slugs={item.data.Magazin_Slugs}
                    />
                )
        }
    })

    return (
        <ArticleContainer>
            <Layout>
                {/* <SEO title="Page two" /> */}
                <span className="kicker">Projekte</span>
                {content}
            </Layout>
        </ArticleContainer>
    )
}

const ArticleContainer = styled.div``
